import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Seo from '../components/Seo';

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="404: Not Found" />
      <Section>
        <h1>404: Not Found</h1>
        <p>Oh nein, die Seite die du suchst, gibt es leider nicht (mehr).</p>
      </Section>
    </Layout>
  );
};

export default NotFoundPage;
